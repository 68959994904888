















import { Component, Prop, Vue } from 'vue-property-decorator'
import { LessonResultStudyContentType } from '@/models/api/lessonResults'

@Component
export default class HistoryLearningResultEntranceContent extends Vue {
  @Prop()
  studyContents!: LessonResultStudyContentType[]

  @Prop({ default: false })
  isTeacher?: boolean

  private contentUrl(studyContent: LessonResultStudyContentType): string {
    const baseUrl = `/${this.isTeacher ? 'teacher' : 'student'}/history/entrance/study-unit`
    return `${baseUrl}/${studyContent.id}?prevPage=${this.$route.name}`
  }

  private drillResultUrl(resultDrillId: number): string {
    return this.isTeacher
      ? `/teacher/history/drillResult/${resultDrillId}?prevPage=${this.$route.name}`
      : `/student/history/drillResult/${resultDrillId}?prevPage=${this.$route.name}`
  }
}
